import Axios from "axios";
import { useFormik } from "formik";
import { BiLockAlt, BiUser } from "react-icons/bi";
import { BsEnvelope } from "react-icons/bs";
import { FaRegUser } from "react-icons/fa";
import { VscGroupByRefType } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Form, FormGroup, Input, Row } from "reactstrap";
import hrmsHostLogo250by250 from "../../assets/images/hrmshost-logo-250x250.png";
import React from "react";

export default function SignIn() {
  const formik = useFormik({
    initialValues: {
      attributes: {
        additionalProp1: "",
      },
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      roles: [""],
      username: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    await Axios.post(`${process.env.REACT_APP_ERMS_ADMIN_API}users`, values)
      .then((response) => {
        console.log("response", response);
      })
      .catch((error) => {
        toast.error("Invalid Email or Password");
      });
  };

  return (
    <>
      <div className="login-6">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-section">
                <div className="logo">
                  <Link to="/">
                    <img
                      width="70%"
                      src={hrmsHostLogo250by250}
                      onerror="this.style.display = 'none';"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="typing">
                  <h1>Company Registration</h1>
                </div>
                <div className="login-inner-form">
                  <Form
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Input
                            type="text"
                            id="additionalProp1"
                            name="additionalProp1"
                            className="form-control"
                            value={formik.values.additionalProp1}
                            onChange={formik.handleChange}
                            placeholder="Additional Prop 1"
                          />
                          <BiUser />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Input
                            type="text"
                            id="firstName"
                            name="firstName"
                            className="form-control"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            placeholder="First Name"
                          />
                          <BiUser />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Input
                            type="text"
                            id="lastName"
                            name="lastName"
                            className="form-control"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            placeholder="Last Name"
                          />
                          <BiUser />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Input
                            type="text"
                            id="email"
                            name="email"
                            className="form-control"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            placeholder="Email"
                          />
                          <BsEnvelope />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Input
                            type="text"
                            id="username"
                            name="username"
                            className="form-control"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            placeholder="Your Email"
                          />
                          <FaRegUser />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Input
                            type="password"
                            className="form-control"
                            id="floatingPassword"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            placeholder="Password"
                          />
                          <BiLockAlt />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Input
                            type="text"
                            id="roles"
                            name="roles"
                            className="form-control"
                            value={formik.values.roles}
                            onChange={formik.handleChange}
                            placeholder="Roles"
                          />
                          <VscGroupByRefType />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="form-group clearfix">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-theme">
                        Register
                      </button>
                    </div>
                  </Form>
                </div>
                <p>
                  Already have an account?
                  <Link
                    to="/login"
                    className="styledLink">
                    Login here
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
