import React, { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { tenantsApi } from "../../apilist/ApiLists";
import AppColor from "../../components/shared/appColor/AppColors";
import { AxiosInstance } from "../../config/AxiosConfig";
import DataTableWrapper from "../../tables/DataTableWrapper";
import SearchBox from "../../tables/SearchBox";
import IntlMessages from "../../util/IntlMessages";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import AddTenant from "./AddTenant";
import PropTypes from "prop-types";
const StatusRender = ({ rowData }) => {
  StatusRender.propTypes = {
    rowData: PropTypes.object.isRequired,
  };
  if (rowData.enabled === true) {
    return <span style={{ color: "#07bc0c", textAlign: "center" }}>Active</span>;
  } else if (rowData.enabled === false) {
    return <span style={{ color: "#FF0000", textAlign: "center" }}>Inactive</span>;
  } else {
    return <span style={{ color: "#964B00", textAlign: "center" }}>--</span>;
  }
};

const ActionButton = ({ rowData, reloadFunction }) => {
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <span style={{ textAlign: "center" }}>
      <FiEdit
        style={{ cursor: "pointer", color: AppColor.Purple }}
        onClick={() => setOpenDialog(true)}
      />
      {openDialog && (
        <AddTenant
          tenantData={rowData}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          reloadFunction={reloadFunction}
        />
      )}
    </span>
  );
};

const TenantList = ({ setIsOpen }) => {
  TenantList.propTypes = {
    setIsOpen: PropTypes.func.isRequired,
  };
  const API = {
    delete: `${tenantsApi}`,
  };

  const [tenants, setTenants] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState("");
  const [searchKey, setSearchKey] = useState("");

  const GetTenantDetails = async (page, rowsPerPage, search = "") => {
    setLoading(true);
    await AxiosInstance.get(`/${tenantsApi}?pageNo=${page}&pageSize=${rowsPerPage}&search=${search}`)
      .then((response) => {
        [...response.resultList[0]].map((row, index) => (row.sno = rowsPerPage * page + (index + 1)));
        setTenants(response.resultList[0]);
        setTotal(response.numberOfItems);
        setLoading(false);
      })
      .catch((error) => {
        setStatusCode(error?.errorCode);
      });
    setLoading(false);
  };

  const columns = [
    { label: "S. No.", accessor: "sno" },
    {
      label: "Schema",
      accessor: "schema",
    },
    {
      label: "User Name",
      accessor: "username",
    },
    {
      label: "Password",
      accessor: "password",
    },
    {
      label: "Status",
      accessor: "StatusRender",
      type: "custom",
      component: StatusRender,
    },
  ];

  const handlePopup = () => {
    setIsOpen(true);
  };

  return (
    <>
      <TitleHeader
        shouldShowArrow={false}
        title="HRMS-Admin"
        pageTitle={"tenants.title"}
      />
      <SearchBox
        content={<IntlMessages id={"addressList.Tittle"} />}
        addLink={false}
        actionName={<IntlMessages id={"add.tenant"} />}
        searchTitle={<IntlMessages id={"address.search.byTittle"} />}
        handleAddBtnClick={handlePopup}
        setSearchKey={setSearchKey}
      />
      <DataTableWrapper
        data={tenants}
        columns={columns}
        count={total}
        loading={loading}
        updateData={GetTenantDetails}
        statusCode={statusCode}
        searchStr={searchKey}
      />
    </>
  );
};

export default TenantList;
