import React, { useEffect, useState } from "react";
import { utilCountApi } from "../../apilist/ApiLists";
import { AxiosInstance } from "../../config/AxiosConfig";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import IntlMessages from "../../util/IntlMessages";

const HomePage = () => {
  const [count, setCount] = useState({});

  const getAllCounts = async () => {
    await AxiosInstance.get(`${utilCountApi}`)
      .then((result) => {
        setCount(result[0]);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getAllCounts();
  }, []);

  return (
    <div>
      <TitleHeader
        shouldShowArrow={false}
        title="HRMS-Admin"
        pageTitle={"dashboard.titles"}
      />
      <div className="row mb-4 columnContainer">
        <div className="col-md-4 mb-4 equalHeightColumn">
          <div className="equalHeightInner">
            <h6 className="CardTittle">
              <IntlMessages id={"text.dashboard.adminDetails"} />
            </h6>
            <table className="cardInnerTable">
              <tbody>
                <tr>
                  <td>
                    <IntlMessages id={"text.dashboard.totalCompanies"} />
                  </td>
                  <td>{count.companies}</td>
                </tr>
                <tr>
                  <td>
                    <IntlMessages id={"text.dashboard.totalSubscriptionPlans"} />
                  </td>
                  <td>{count.plans}</td>
                </tr>
                <tr>
                  <td>
                    <IntlMessages id={"text.dashboard.totalCompanySubscription"} />
                  </td>
                  <td>{count.subscriptions}</td>
                </tr>
                <tr>
                  <td>
                    <IntlMessages id={"text.dashboard.totalTenants"} />
                  </td>
                  <td>{count.tenants}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
