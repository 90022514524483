import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { Col, Row } from "reactstrap";
import { orderDetailsApi } from "../../apilist/ApiLists";
import AppColor from "../../components/shared/appColor/AppColors";
import { AxiosInstance } from "../../config/AxiosConfig";
import DataTableWrapper from "../../tables/DataTableWrapper";
import SearchBox from "../../tables/SearchBox";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import UpdateOrderDetail from "./UpdateOrderDetail";

const ActionButton = ({ rowData, reloadFunction }) => {
  ActionButton.propTypes = {
    rowData: PropTypes.object,
    reloadFunction: PropTypes.func,
  };
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <span style={{ textAlign: "center" }}>
      <FiEdit
        style={{ cursor: "pointer", color: AppColor.Pink }}
        onClick={() => setOpenDialog(true)}
      />
      {openDialog && (
        <UpdateOrderDetail
          orderDetailsData={rowData}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          reloadFunction={reloadFunction}
        />
      )}
    </span>
  );
};

const OrderDetailList = ({ setIsOpen, reloadDataList, setReloadDataList }) => {
  OrderDetailList.propTypes = {
    setIsOpen: PropTypes.func.isRequired,
    reloadDataList: PropTypes.bool.isRequired,
    setReloadDataList: PropTypes.func.isRequired,
  };
  const [orderDetail, setOrderDetailData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const orderDetailData = async (page, rowsPerPage, search = "") => {
    setLoading(true);
    try {
      const result = await AxiosInstance.get(`/${orderDetailsApi}?pageNo=${page}&pageSize=${rowsPerPage}&search=${search}`);

      if (result && result.resultList && result.resultList[0]) {
        result.resultList[0].forEach((row, index) => {
          row.sno = rowsPerPage * page + (index + 1);
        });
        setOrderDetailData(result.resultList[0]);
        setTotal(result.numberOfItems);
      } else {
        console.error("No data available in the result.");
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { label: "S. No.", accessor: "sno" },
    { label: "Order Date", accessor: "orderdate" },
    { label: "Company Code", accessor: "companyCode" },
    { label: "Company Name", accessor: "companyName" },
    { label: "Plan Code", accessor: "planCode" },
    { label: "Plan Name", accessor: "planName" },
    { label: "Plan Price", accessor: "planPrice", type: "currency_Paisa" },
    { label: "Disc. %", accessor: "discountPercent", type: "number" },
    { label: "Disc. Amount", accessor: "discountAmount", type: "currency_Paisa" },
    { label: "Net Price", accessor: "netAmount", type: "currency_Paisa" },
    { label: "CGST(%)", accessor: "cgstRate" },
    { label: "CGST Amount", accessor: "cgstAmount", type: "currency_Paisa" },
    { label: "SGST(%)", accessor: "sgstRate" },
    { label: "SGST Amount", accessor: "sgstAmount", type: "currency_Paisa" },
    { label: "IGST(%)", accessor: "igstRate" },
    { label: "IGST Amount", accessor: "igstAmount", type: "currency_Paisa" },
    { label: "Total Price", accessor: "totalAmount", type: "currency_Paisa" },
    {
      label: "Status",
      accessor: "status",
    },
    {
      label: "Action",
      accessor: "custom",
      type: "actionButtonWithUpdate",
      component: ({ rowData }) => {
        return (
          <>
            <Row>
              <Col>
                <ActionButton
                  rowData={rowData}
                  reloadFunction={orderDetailData}
                />
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  const handlePopup = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (reloadDataList) {
      orderDetailData(0, 10, "");
      setReloadDataList(false);
    }
  }, [reloadDataList]);

  return (
    <>
      <TitleHeader
        shouldShowArrow={false}
        title="HRMS-Admin"
        pageTitle={"orderDetail.title"}
      />
      <SearchBox
        handleAddBtnClick={handlePopup}
        setSearchKey={setSearchKey}
      />
      <DataTableWrapper
        data={orderDetail}
        columns={columns}
        count={total}
        loading={loading}
        updateData={orderDetailData}
        searchStr={searchKey}
      />
    </>
  );
};

export default OrderDetailList;
