import React from "react";
import AppConstant from "../../../constants/AppConstant";
export default function Copyright(props) {
  return (
    <>
      <p className="text-center">
        <div>A product of</div> ©&nbsp;{AppConstant.copyRightText} <div><br/>Pvt. Ltd. {new Date().getFullYear()}</div>
      </p>
    </>
  );
}
