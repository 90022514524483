import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { Col, Row } from "reactstrap";
import { createPlanAPI } from "../../apilist/ApiLists";
import AppColor from "../shared/appColor/AppColors";
import { AxiosInstance } from "../../config/AxiosConfig";
import DataTableWrapper from "../../tables/DataTableWrapper";
import SearchBox from "../../tables/SearchBox";
import IntlMessages from "../../util/IntlMessages";
import DeleteButton from "../shared/DeleteButton";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import AddPlan from "./AddPlan";
import PropTypes from "prop-types";

const ActionButton = ({ rowData, reloadFunction }) => {
  ActionButton.propTypes = {
    rowData: PropTypes.object.isRequired,
    reloadFunction: PropTypes.func.isRequired,
  };
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <span style={{ textAlign: "center" }}>
      <FiEdit
        style={{ cursor: "pointer", color: AppColor.Pink }}
        onClick={() => setOpenDialog(true)}
      />
      {openDialog && (
        <AddPlan
          subscriptionData={rowData}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          reloadFunction={reloadFunction}
        />
      )}
    </span>
  );
};

const StatusRender = ({ rowData }) => {
  if (rowData.active === true) {
    return <span style={{ color: "#07bc0c", textAlign: "center" }}>Active</span>;
  } else if (rowData.active === false) {
    return <span style={{ color: "#FF0000", textAlign: "center" }}>Inactive</span>;
  } else {
    return <span style={{ color: "#964B00", textAlign: "center" }}>--</span>;
  }
};

const PlanList = ({ setIsOpen, reloadDataList, setReloadDataList }) => {
  PlanList.propTypes = {
    setIsOpen: PropTypes.func.isRequired,
    reloadDataList: PropTypes.bool.isRequired,
    setReloadDataList: PropTypes.func.isRequired,
  };
  const API = {
    delete: `${createPlanAPI}`,
  };

  const [subscriptionData, setSubscriptionData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState("");
  const [searchKey, setSearchKey] = useState("");

  const SubscriptionsData = async (page, rowsPerPage, search = "") => {
    setLoading(true);
    await AxiosInstance.get(`/${createPlanAPI}?pageNo=${page}&pageSize=${rowsPerPage}&search=${search}`)
      .then((result) => {
        [...result.resultList[0]].map((row, index) => (row.sno = rowsPerPage * page + (index + 1)));
        setSubscriptionData(result.resultList[0]);
        setTotal(result.numberOfItems);
        setLoading(false);
      })
      .catch((error) => {
        setStatusCode(error?.errorCode);
      });
    setLoading(false);
  };

  const columns = [
    { label: "S. No.", accessor: "sno" },
    { label: "Plan Name", accessor: "planName" },
    { label: "Subs Code", accessor: "planCode" },
    { label: "Max Employee", accessor: "maxEmployee", type: "number" },
    { label: "Max Asset", accessor: "maxAsset", type: "number" },
    { label: "Max Project", accessor: "maxProject", type: "number" },
    { label: "Max Document", accessor: "maxDocument", type: "number" },
    { label: "Plan Price", accessor: "planPrice", type: "currency" },
    { label: "Duration Type", accessor: "durationType" },
    {
      label: "Status",
      accessor: "StatusRender",
      type: "custom",
      component: StatusRender,
    },
    { label: "DOM", accessor: "dateModified", type: "dateFun" },
    {
      label: "Action",
      accessor: "custom",
      type: "actionButtonWithUpdate",
      component: ({ rowData }) => {
        return (
          <>
            <Row>
              <Col className="action-padding">
                <ActionButton
                  rowData={rowData}
                  reloadFunction={SubscriptionsData}
                />
              </Col>
              <Col className="action-padding">
                <DeleteButton
                  api={API}
                  Id={rowData.id}
                  refreshList={SubscriptionsData}
                  message={" Address"}
                />
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  const handlePopup = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (reloadDataList) {
      SubscriptionsData(0, 10, "");
      setReloadDataList(false);
    }
  }, [reloadDataList]);

  return (
    <>
      <TitleHeader
        shouldShowArrow={false}
        title="HRMS-Admin"
        pageTitle={"subscription.plan.title"}
      />
      <SearchBox
        addLink={true}
        actionName={<IntlMessages id={"add.plan"} />}
        handleAddBtnClick={handlePopup}
        setSearchKey={setSearchKey}
      />
      <DataTableWrapper
        data={subscriptionData}
        columns={columns}
        count={total}
        loading={loading}
        updateData={SubscriptionsData}
        statusCode={statusCode}
        searchStr={searchKey}
      />
    </>
  );
};

export default PlanList;
