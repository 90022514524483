import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import Header from "../navbar/Header";
import MenuList from "./MenuList";
import SidebarMenu from "./SidebarMenu";
import PropTypes from "prop-types";

const SideBar = ({ children }) => {
  SideBar.propTypes = {
    children: PropTypes.node.isRequired,
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
        type: "spring",
        damping: 10,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
        type: "spring",
        damping: 10,
      },
    },
  };

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  const ResponsiveWdth = windowDimenion.winWidth;
  const breakPoint = 1090;

  useEffect(() => {
    if (ResponsiveWdth < breakPoint) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [breakPoint, ResponsiveWdth]);

  return (
    <>
      <Header
        toggleSideBar={toggle}
        isOpen={isOpen}
        ResponsiveWdth={ResponsiveWdth}
        breakPoint={breakPoint}
      />
      <div className="main-container">
        {isOpen && (
          <AnimatePresence>
            <motion.div
              id="sidebar"
              animate={{
                width: "270px",
                display: isOpen ? "block" : "none",
                transition: {
                  duration: 0.5,
                  type: "spring",
                  damping: 10,
                },
              }}
              className={`sidebar ${isOpen ? "sidebarActive" : "sidebarInactive"} ${ResponsiveWdth < breakPoint ? "SideBarMobile" : "SideBarDesktop"}`}>
              <section className="routes">
                {MenuList.map((menu, index) => {
                  if (menu.subRoutes) {
                    return (
                      <SidebarMenu
                        setIsOpen={setIsOpen}
                        route={menu}
                        showAnimation={showAnimation}
                        isOpen={isOpen}
                      />
                    );
                  }
                  return (
                    <>
                      <NavLink
                        to={menu.path}
                        key={index}
                        className={menu.shouldDisable ? "noLink" : "link"}
                        activeClassName={menu.shouldDisable ? "" : "active"}
                        onClick={() => ResponsiveWdth < breakPoint && toggle()}>
                        <div className="icon">{menu.icon}</div>
                        <AnimatePresence>
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="link_text">
                            <IntlMessages id={menu.title} />
                          </motion.div>
                        </AnimatePresence>
                      </NavLink>
                    </>
                  );
                })}
              </section>
              <div
                id="overlay"
                className="overlay"
                onClick={toggle}></div>
            </motion.div>
          </AnimatePresence>
        )}

        <div className="Content_section">
          <div className="content-inner">{children}</div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
