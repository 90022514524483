export const monthShortName = (dateValue) => {
  const date = new Date(dateValue);
  return date.toLocaleString("en-US", {
    month: "short",
  });
};

export const dayShortName = (dateValue) => {
  const d = new Date(dateValue);
  switch (d.getDay()) {
    case 0:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fri";
    case 6:
      return "Sat";
  }
};

export const toMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);
  return monthShortName(date);
};

export const monthIn2Digit = (dateValue) => {
  const date = new Date(dateValue);
  switch (date.getMonth()) {
    case 0:
      return "01";
    case 1:
      return "02";
    case 2:
      return "03";
    case 3:
      return "04";
    case 4:
      return "05";
    case 5:
      return "06";
    case 6:
      return "07";
    case 7:
      return "08";
    case 8:
      return "09";
    case 9:
      return "10";
    case 10:
      return "11";
    case 11:
      return "12";
  }
};

export const dayAndMonth = (dateValue) => {
  const d = new Date(dateValue);
  const date = d.getDate();
  return ("0" + date).slice(-2) + " " + monthShortName(dateValue);
};

export const dateTimeFormatter = (dateValue) => {
  const d = new Date(dateValue);
  const date = d.getDate();
  const month = monthShortName(d);
  const year = d.getFullYear();
  return date + " " + month + " " + year + " " + d.toString().substr(16, 8);
};

export const dateFormatter = (dateValue) => {
  const d = new Date(dateValue);
  const date = d.getDate();
  const month = monthShortName(d);
  const year = d.getFullYear();
  return ("0" + date).slice(-2) + "-" + month + " " + year;
};

export const dateFormatterWithTime = (dateValue) => {
  const d = new Date(dateValue);
  const date = d.getDate();
  const month = monthShortName(d);
  const year = d.getFullYear();
  const hour = d.getHours();
  const minute = d.getMinutes();
  const second = d.getSeconds();
  const median = d.getHours() > 12 ? "pm" : "am";
  return "(" + ("0" + date).slice(-2) + " " + month + " " + year + ", " + hour + ":" + minute + ":" + second + " " + median + ")";
};

export const states = [
  { name: "Andhra Pradesh", code: "AP" },
  { name: "Arunachal Pradesh", code: "AR" },
  { name: "Assam", code: "AS" },
  { name: "Bihar", code: "BR" },
  { name: "Chhattisgarh", code: "CG" },
  { name: "Goa", code: "GA" },
  { name: "Gujarat", code: "GJ" },
  { name: "Haryana", code: "HR" },
  { name: "Himachal Pradesh", code: "HP" },
  { name: "Jharkhand", code: "JH" },
  { name: "Karnataka", code: "KA" },
  { name: "Kerala", code: "KL" },
  { name: "Madhya Pradesh", code: "MP" },
  { name: "Maharashtra", code: "MH" },
  { name: "Manipur", code: "MN" },
  { name: "Meghalaya", code: "ML" },
  { name: "Mizoram", code: "MZ" },
  { name: "Nagaland", code: "NL" },
  { name: "Odisha", code: "OR" },
  { name: "Punjab", code: "PB" },
  { name: "Rajasthan", code: "RJ" },
  { name: "Sikkim", code: "SK" },
  { name: "Tamil Nadu", code: "TN" },
  { name: "Telangana", code: "TG" },
  { name: "Tripura", code: "TR" },
  { name: "Uttar Pradesh", code: "UP" },
  { name: "Uttarakhand", code: "UK" },
  { name: "West Bengal", code: "WB" },
  { name: "Andaman and Nicobar Islands", code: "UT AN" },
  { name: "Chandigarh", code: "UT CH" },
  { name: "Dadra and Nagar Haveli and Daman and Diu", code: "UT DN" },
  { name: "Delhi", code: "UT DL" },
  { name: "Lakshadweep", code: "UT LD" },
  { name: "Puducherry", code: "UT PY" },
];

export const SlabType = [{ name: "Monthly" }, { name: "Annually" }, { name: "Half-Yearly" }];

export const Month = [{ name: "Monthly" }, { name: "Annually" }, { name: "Half-Yearly" }];

export const month = [
  { name: "Jan", code: "Jan" },
  { name: "Feb", code: "Feb" },
  { name: "Mar", code: "Mar" },
  { name: "Apr", code: "Apr" },
  { name: "May", code: "May" },
  { name: "Jun", code: "Jun" },
  { name: "Jul", code: "Jul" },
  { name: "Aug", code: "Aug" },
  { name: "Sep", code: "Sep" },
  { name: "Oct", code: "Oct" },
  { name: "Nov", code: "Nov" },
  { name: "Dec", code: "Dec" },
];

export const gender = [{ name: "Male " }, { name: "Female" }];
