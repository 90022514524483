import { FormControlLabel, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { getAllEnums, createPlanAPI } from "../../apilist/ApiLists";
import AppColor from "../shared/appColor/AppColors";
import { AxiosInstance } from "../../config/AxiosConfig";
import IntlMessages from "../../util/IntlMessages";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import ToastMessage from "../shared/ToastMessage";
import * as yup from "yup";
import PropTypes from "prop-types";

const AddPlan = ({ openDialog, setOpenDialog, subscriptionData: planData, reloadFunction, setReloadDataList }) => {
  AddPlan.propTypes = {
    openDialog: PropTypes.bool.isRequired,
    setOpenDialog: PropTypes.func.isRequired,
    subscriptionData: PropTypes.object.isRequired,
    reloadFunction: PropTypes.func.isRequired,
    setReloadDataList: PropTypes.func.isRequired,
  };
  const planId = planData && planData.id;

  const [durationTypes, setDurationTypes] = useState([]);
  const [planTypes, setPlanTypes] = useState([]);

  let validationSchema = yup.object().shape({
    planName: yup.string().trim().required(""),
    planCode: yup.string().trim().required(""),
    planType: yup.string().trim().required(""),
    durationType: yup.string().trim().required(""),
    month: yup.string().trim().required(""),
    days: yup.string().trim().required(""),
    maxEmployee: yup.string().trim().required(""),
    unitRate: yup.string().trim().required(""),
    assetUnitRate: yup.string().trim().required(""),
    projectUnitRate: yup.string().trim().required(""),
    documentUnitRate: yup.string().trim().required(""),
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      planName: "",
      planCode: "",
      planType: "",
      durationType: "",
      month: "",
      days: "",
      maxEmployee: "",
      unitRate: "",
      planPrice: "",
      assetUnitRate: "",
      projectUnitRate: "",
      documentUnitRate: "",
      dateModified: "",
      maxAsset: "",
      maxDocument: "",
      maxProject: "",
      modifiedBy: "",
      active: true,
      comment: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger">{formik.errors[field]}</div>;
  };

  const planPrice = formik?.values?.maxEmployee * formik?.values?.month * formik?.values?.unitRate * 30;
  const maxAssetUnit = formik?.values?.maxEmployee * formik?.values?.assetUnitRate;
  const maxProjectUnit = formik?.values?.maxEmployee * formik?.values?.projectUnitRate;
  const maxDocumentUnit = formik?.values?.maxEmployee * formik?.values?.documentUnitRate;

  const createPlan = async (values) => {
    await AxiosInstance.post(`/${createPlanAPI}`, values).then((response) => {
      setReloadDataList((prev) => !prev);
      setOpenDialog(false);
    });
  };

  const updatePlan = async (values) => {
    await AxiosInstance.put(`/${createPlanAPI}/${planId}`, values).then((response) => {
      setOpenDialog(false);
      reloadFunction && reloadFunction(0, 10, "");
    });
  };

  const handleSubmit = async (values) => {
    if (planId) {
      updatePlan(values);
    } else {
      createPlan(values);
    }
  };

  const allEnums = async () => {
    await AxiosInstance.get(`${getAllEnums}`).then((response) => {
      setDurationTypes(response?.planDurationTypeEnum);
      setPlanTypes(response?.planTypeEnum);
    });
  };

  useEffect(() => {
    allEnums();
  }, []);

  useEffect(() => {
    if (planData) {
      formik.setValues(planData);
    }
  }, [planData]);

  useEffect(() => {
    formik.setFieldValue("planPrice", planPrice);
    formik.setFieldValue("maxAsset", maxAssetUnit);
    formik.setFieldValue("maxProject", maxProjectUnit);
    formik.setFieldValue("maxDocument", maxDocumentUnit);
  }, [formik.values]);

  const toggleChecked = (e) => {
    formik.setFieldValue("active", e.target.checked);
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  if (formik.isSubmitting && formik.isValidating && !formik.isValid) {
    toast.error("Please fill all the mandatory fields", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <>
      <ToastMessage />
      <ModelSelfFooter
        open={openDialog}
        heading={planId ? <IntlMessages id="update.plan" /> : <IntlMessages id="add.plan" />}
        closePopUp={
          <CgClose
            onClick={() => setOpenDialog(false)}
            className="popUpCloseIcon"
          />
        }
        closeFn={() => setOpenDialog(false)}
        maxWidth="xs"
        footer={<span className="DisplayNone">.</span>}>
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          className="mdForm"
          onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="planName"
                  className="requiredField">
                  <IntlMessages id="update.plan.planName" />
                </Label>
                <Input
                  type="text"
                  id="planName"
                  name="planName"
                  value={formik.values.planName}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.planName && !formik.values.planName}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("planName")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="planCode"
                  className="requiredField">
                  <IntlMessages id="update.plan.planCode" />
                </Label>
                <Input
                  type="text"
                  id="planCode"
                  name="planCode"
                  value={formik.values.planCode}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.planCode && !formik.values.planCode}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("planCode")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="planType"
                  className="requiredField">
                  <IntlMessages id="update.plan.planType" />
                </Label>
                <Input
                  type="select"
                  id="planType"
                  name="planType"
                  value={formik.values.planType}
                  onChange={formik.handleChange}
                  className="input-select-field-css"
                  invalid={formik.touched.planType && !formik.values.planType}
                  onBlur={formik.handleBlur}>
                  <option value="">-- Select --</option>
                  {planTypes &&
                    Object.values(planTypes).map((value, index) => (
                      <option
                        key={index}
                        value={value}>
                        {value}
                      </option>
                    ))}
                </Input>
                {renderErrorMessage("planType")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="enabled"
                  className="requiredField">
                  <IntlMessages id="update.plan.durationType" />
                </Label>
                <Input
                  type="select"
                  id="durationType"
                  name="durationType"
                  value={formik.values.durationType}
                  onChange={formik.handleChange}
                  className="input-select-field-css"
                  invalid={formik.touched.durationType && !formik.values.durationType}
                  onBlur={formik.handleBlur}>
                  <option value="">-- Select --</option>
                  {durationTypes && Object.values(durationTypes).map((value) => <option value={value}>{value}</option>)}
                </Input>
                {renderErrorMessage("durationType")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="month"
                  className="requiredField">
                  <IntlMessages id="update.plan.month" />
                </Label>
                <Input
                  type="text"
                  id="month"
                  name="month"
                  value={formik.values.month}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.month && !formik.values.month}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("month")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="days"
                  className="requiredField">
                  <IntlMessages id="update.plan.days" />
                </Label>
                <Input
                  type="text"
                  id="days"
                  name="days"
                  value={formik.values.days}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.days && !formik.values.days}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("days")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="maxEmployee"
                  className="requiredField">
                  <IntlMessages id="update.plan.maxEmployee" />
                </Label>
                <Input
                  type="number"
                  id="maxEmployee"
                  name="maxEmployee"
                  value={formik.values.maxEmployee}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.maxEmployee && !formik.values.maxEmployee}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("maxEmployee")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="unitRate"
                  className="requiredField">
                  <IntlMessages id="update.plan.unitRate" />
                </Label>
                <Input
                  type="number"
                  id="unitRate"
                  name="unitRate"
                  value={formik.values.unitRate}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.unitRate && !formik.values.unitRate}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("unitRate")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="planPrice">
                  <IntlMessages id="update.plan.planPrice" />
                </Label>
                <Input
                  type="number"
                  id="planPrice"
                  name="planPrice"
                  value={planPrice}
                  onChange={(e, value) => {
                    formik.setFieldValue("planPrice", planPrice);
                  }}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="assetUnitRate"
                  className="requiredField">
                  <IntlMessages id="update.plan.assetUnitRate" />
                </Label>
                <Input
                  type="number"
                  id="assetUnitRate"
                  name="assetUnitRate"
                  value={formik.values.assetUnitRate}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.assetUnitRate && !formik.values.assetUnitRate}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("assetUnitRate")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="projectUnitRate"
                  className="requiredField">
                  <IntlMessages id="update.plan.projectUnitRate" />
                </Label>
                <Input
                  type="number"
                  id="projectUnitRate"
                  name="projectUnitRate"
                  value={formik.values.projectUnitRate}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.projectUnitRate && !formik.values.projectUnitRate}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("projectUnitRate")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="documentUnitRate"
                  className="requiredField">
                  <IntlMessages id="update.plan.documentUnitRate" />
                </Label>
                <Input
                  type="number"
                  id="documentUnitRate"
                  name="documentUnitRate"
                  value={formik.values.documentUnitRate}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.documentUnitRate && !formik.values.documentUnitRate}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("documentUnitRate")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="maxAsset">
                  <IntlMessages id="update.plan.maxAsset" />
                </Label>
                <Input
                  type="number"
                  id="maxAsset"
                  name="maxAsset"
                  value={maxAssetUnit}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="maxProject">
                  <IntlMessages id="update.plan.maxProject" />
                </Label>
                <Input
                  type="number"
                  id="maxProject"
                  name="maxProject"
                  value={maxProjectUnit}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="maxDocument">
                  <IntlMessages id="update.plan.maxDocument" />
                </Label>
                <Input
                  type="number"
                  id="maxDocument"
                  name="maxDocument"
                  value={maxDocumentUnit}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {planId && (
              <>
                <Col>
                  <FormGroup>
                    <Label htmlFor="modifiedBy">
                      <IntlMessages id="update.plan.modifiedBy" />
                    </Label>
                    <Input
                      type="text"
                      id="modifiedBy"
                      name="modifiedBy"
                      value={formik.values.modifiedBy}
                      onChange={formik.handleChange}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor="dateModified">
                      <IntlMessages id="update.plan.modifiedDate" />
                    </Label>
                    <Input
                      type="date"
                      id="dateModified"
                      name="dateModified"
                      value={formik.values.dateModified}
                      onChange={formik.handleChange}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </>
            )}
            <Col>
              <FormGroup>
                <Label
                  htmlFor="planStatus"
                  className="statusLabel requiredField">
                  <IntlMessages id="update.plan.planStatus" />
                </Label>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={formik.values.active}
                      onChange={toggleChecked}
                    />
                  }
                  className="switchButton"
                  label={formik.values.active ? <b>True</b> : <b>False</b>}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="comment">
                  <IntlMessages id="update.plan.comment" />
                </Label>
                <Input
                  type="textarea"
                  id="comment"
                  name="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row></Row>
          <Row className="FooterForm">
            <Button
              variant="contained"
              color="outline-secondary"
              onClick={() => setOpenDialog(false)}
              className="buttonSpacing">
              <IntlMessages id={`button.text.cancel`} />
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{ backgroundImage: `${AppColor.BackgroundImage}` }}
              className="buttonSpacing">
              <IntlMessages id={`button.text.save`} />
            </Button>
          </Row>
        </Form>
      </ModelSelfFooter>
    </>
  );
};
export default AddPlan;
