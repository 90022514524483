import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { CgClose } from "react-icons/cg";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { orderDetailsApi } from "../../apilist/ApiLists";
import AppColor from "../../components/shared/appColor/AppColors";
import { AxiosInstance } from "../../config/AxiosConfig";
import { dateFormatterWithTime } from "../../util/Helper";
import IntlMessages from "../../util/IntlMessages";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import ToastMessage from "../shared/ToastMessage";

const UpdateOrderDetail = ({ openDialog, setOpenDialog, orderDetailsData, reloadFunction }) => {
  UpdateOrderDetail.propTypes = {
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func,
    orderDetailsData: PropTypes.object,
    reloadFunction: PropTypes.func,
  };

  const orderDetailDataById = orderDetailsData && orderDetailsData.id;

  const formik = useFormik({
    initialValues: {
      id: "",
      planPrice: 0,
      taxRate: 0,
      taxAmount: 0,
      netAmount: 0,
      createdBy: "",
      planId: "",
      planName: "",
      companyCode: "",
      orderDate: "",
      planCode: "",
      discountPercent: "",
      discountAmount: "",
      razorpayOrderId: "",
      razorpayPaymentId: "",
      razorpaySignature: "",
      status: "",
      errorObj: null,
    },

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    await AxiosInstance.put(`/${orderDetailsApi}/${orderDetailDataById}`, values)
      .then((response) => {
        setOpenDialog(false);
        reloadFunction && reloadFunction(0, 10, "");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (orderDetailsData) {
      formik.setValues(orderDetailsData);
    }
  }, [orderDetailsData]);

  return (
    <>
      <ToastMessage />
      <ModelSelfFooter
        open={openDialog}
        heading={<IntlMessages id="title.orderDetail" />}
        closePopUp={
          <CgClose
            onClick={() => setOpenDialog(false)}
            className="popUpCloseIcon"
          />
        }
        closeFn={() => setOpenDialog(false)}
        maxWidth="xs"
        footer={<span className="DisplayNone">.</span>}>
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          className="mdForm"
          onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="createdBy">Created By</Label>
                <Input
                  type="text"
                  id="createdBy"
                  name="createdBy"
                  value={formik.values.createdBy}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="orderDate">Order Date</Label>
                <Input
                  type="text"
                  id="orderDate"
                  name="orderDate"
                  value={dateFormatterWithTime(formik.values.orderDate)}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="companyCode">Company Code</Label>
                <Input
                  type="text"
                  id="companyCode"
                  name="companyCode"
                  value={formik.values.companyCode}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="planCode">Plan Code</Label>
                <Input
                  type="text"
                  id="planCode"
                  name="planCode"
                  value={formik.values.planCode}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="planName">Plan Name</Label>
                <Input
                  type="text"
                  id="planName"
                  name="planName"
                  value={formik.values.planName}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="planPrice">Price</Label>
                <Input
                  type="text"
                  id="planPrice"
                  name="planPrice"
                  value={formik.values.planPrice / 100}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="discountPercent">Discount ( % )</Label>
                <Input
                  type="text"
                  id="discountPercent"
                  name="discountPercent"
                  value={formik.values.discountPercent}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="discountAmount">Discount Amount</Label>
                <Input
                  type="text"
                  id="discountAmount"
                  name="discountAmount"
                  value={formik.values.discountAmount / 100}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="taxRate">GST ( % )</Label>
                <Input
                  type="text"
                  id="taxRate"
                  name="taxRate"
                  value={formik.values.taxRate}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="taxAmount">Tax Amount</Label>
                <Input
                  type="text"
                  id="taxAmount"
                  name="taxAmount"
                  value={formik.values.taxAmount / 100}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="netAmount">Net Amount</Label>
                <Input
                  type="text"
                  id="netAmount"
                  name="netAmount"
                  value={formik.values.netAmount / 100}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="razorpayOrderId">Razorpay Order Id</Label>
                <Input
                  type="text"
                  id="razorpayOrderId"
                  name="razorpayOrderId"
                  value={formik.values.razorpayOrderId}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="razorpayPaymentId">Razorpay Payment Id</Label>
                <Input
                  type="text"
                  id="razorpayPaymentId"
                  name="razorpayPaymentId"
                  value={formik.values.razorpayPaymentId}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="status">Status</Label>
                <Input
                  type="text"
                  id="status"
                  name="status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="FooterForm">
            <Button
              variant="contained"
              color="outline-secondary"
              onClick={() => setOpenDialog(false)}
              className="buttonSpacing">
              <IntlMessages id={`button.text.cancel`} />
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{ backgroundImage: `${AppColor.BackgroundImage}` }}
              className="buttonSpacing"
              disabled>
              <IntlMessages id={`button.text.save`} />
            </Button>
          </Row>
        </Form>
      </ModelSelfFooter>
    </>
  );
};
export default UpdateOrderDetail;
